import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { BodyUI, sansFamily } from "@happeouikit/typography";
import { uuidv4 } from "./utils";
import { TextareaProps } from "./Textarea.types";
import { IconAlertCircleOutline } from "@happeouikit/icons";
import { TinyText } from "@happeouikit/typography";

/**
 * Map input states to colors
 * @type {{default: string, success: string, error: string}}
 */
const inputStates = {
  success: "var(--color-success-primary, #00a372)",
  error: "var(--color-alert-primary, #da3939)",
  default: "var(--color-secondary-text-on-light, #4a5464)",
};

const Textarea = ({
  id,
  cols,
  rows,
  label,
  description,
  required,
  readOnly,
  disabled,
  state,
  errorMessage,
  hintText,
  maxHeight,
  ...props
}: TextareaProps) => {
  const [appliedId] = useState(() => id || uuidv4());
  const accessibility = label ? { "aria-labelledby": id } : {};
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const describedBy = [
    description ? `${appliedId}-description` : "",
    hintText ? `${appliedId}-hint-message` : "",
    state === "error" && errorMessage ? `${appliedId}-error-message` : "",
  ].join(" ");

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = `${Math.min(
        textareaRef.current.scrollHeight,
        maxHeight || 9999
      )}px`;
    }
  }, [maxHeight]);

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    // Handle auto height
    if (props.autoHeight) {
      const { target } = event;

      // First set height to inherit to allow textarea to contract
      target.style.height = "inherit";
      target.style.height = `${target.scrollHeight}px`;
    }

    // call props.onChange if exists
    if (typeof props.onChange === "function") {
      props.onChange(event);
    }
  };

  return (
    <Container isDisabled={disabled || false}>
      {!!label && (
        <InputLabelContainer>
          <InputLabel bold htmlFor={appliedId} isRequired={required || false}>
            {label}
          </InputLabel>
          {description && (
            <BodyUI
              id={`${appliedId}-description`}
              color="var(--color-secondary-text-on-light, #4a5464)"
            >
              {description}
            </BodyUI>
          )}
        </InputLabelContainer>
      )}
      <StyledTextarea
        title={label || "Styled input"}
        state={state || "default"}
        id={appliedId}
        disabled={disabled}
        readOnly={readOnly}
        ref={textareaRef}
        cols={cols}
        rows={rows}
        {...(description
          ? {
              "aria-describedby": describedBy,
            }
          : {})}
        {...accessibility}
        {...props}
        style={{
          ...props.style,
          maxHeight,
        }}
        onChange={onChange}
      />
      {hintText && (
        <HintContainer id={`${appliedId}-hint-message`}>
          <HintIcon fill="var(--color-hint-text-on-light, #68778d)" />
          <TinyText color="var(--color-secondary-text-on-light, #4a5464)">
            {hintText}
          </TinyText>
        </HintContainer>
      )}

      {state === "error" && errorMessage && (
        <HintContainer id={`${appliedId}-error-message`}>
          <HintIcon fill="var(--color-alert-primary, #da3939)" />
          <TinyText color="var(--color-alert-primary, #da3939)">
            {errorMessage}
          </TinyText>
        </HintContainer>
      )}
    </Container>
  );
};

const HintIcon = styled(IconAlertCircleOutline)`
  width: 13px;
  height: 13px;
`;

const HintContainer = styled.div`
  display: flex;
  gap: var(--space-xs, 4px);
  margin-top: var(--space-sm, 8px);
  align-items: center;
`;

const InputLabel = styled(BodyUI).attrs({
  as: "label",
})<{ isRequired: boolean }>`
  color: var(--color-primary-text-on-light, #2b313b);
  position: relative;
  ${({ isRequired }) =>
    isRequired &&
    `
    &::after {
      content: '*';
      color: var(--color-alert-primary, #da3939);
      margin-left: var(--space-xxs, 2px);      
    }
  `}
`;

const InputLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-xxs, 2px);
  margin-bottom: var(--space-sm, 8px);
`;

const StyledTextarea = styled.textarea<{
  state: "success" | "error" | "default";
}>`
  ::placeholder {
    font-family: ${sansFamily};
    color: var(--color-placeholder-text-on-light, #95a0b1);
    letter-spacing: -0.3px;
    font-size: 14px;
    font-weight: normal;
  }

  font-family: ${sansFamily};
  border-radius: var(--radius-md, 4px);
  ${(props) => (props.cols ? "" : "width: 100%;")};
  ${(props) => (props.rows ? "" : "height: 100%;")};
  padding: var(--space-sm-plus, 10px) var(--space-md-minus, 12px);
  border: solid 1px var(--color-stroke, #95a0b1);
  background-color: var(--color-surface, #ffffff);
  box-sizing: border-box;
  box-shadow: none;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.3px;
  font-size: var(--font-size-body-sm, 14px);
  color: var(--color-primary-text-on-light, #2b313b);

  :hover {
    border-color: ${({ state = "default" }) =>
      state === "default"
        ? "var(--color-stroke-darken, #596578)"
        : inputStates[state]};
  }

  :active {
    outline: none;
    box-shadow: none;
    border-color: ${({ state = "default" }) =>
      state === "default"
        ? "var(--color-focus-indicator, #1691fe)"
        : inputStates[state]};
  }

  :focus {
    outline: none;
    border-color: ${({ state = "default" }) =>
      state === "default"
        ? "var(--color-focus-indicator, #1691fe)"
        : inputStates[state]} !important;
  }

  :disabled {
    border: solid 1px var(--color-stroke-lighten);
    color: var(--color-gray500, #68778d);

    ::placeholder {
      color: var(--color-gray500, #68778d);
    }
  }

  :read-only {
    background-color: var(--color-surface-darken, #f3f5f7);
    border: solid 1px var(--color-disabled-text-on-light);
    color: var(--color-secondary-text-on-light, #4a5464);
  }

  border-color: ${({ state = "default" }) => inputStates[state]};
`;

const Container = styled.div<{
  isDisabled: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  ${({ isDisabled }) =>
    isDisabled &&
    `
    ${InputLabelContainer},
    ${StyledTextarea},
    ${HintContainer} {
      opacity: 0.4;
    }
  `}
`;

export default Textarea;
