import { ChangeEvent, useCallback, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { BodyUI } from "@happeouikit/typography";
import { IconCheck, IconRemove } from "@happeouikit/icons";
import { uuidv4 } from "./utils";
import { CheckboxProps } from "./Checkbox.types";

const Checkbox = ({
  label,
  subtitle,
  description,
  indeterminate,
  onChange,
  style,
  disabled,
  checked,
  id,
  background,
  "data-tip": dataTip1,
  "data-tooltip-content": dataTip2,
  "data-tooltip-id": dataTooltipId,
  "data-testid": dataTestId,
  ...props
}: CheckboxProps) => {
  const checkboxRef = useRef<HTMLInputElement | null>(null);
  const assignedId = useMemo(() => id || uuidv4(), [id]);

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate || false;
    }
  }, [indeterminate]);

  const onClick = useCallback(
    (event: React.MouseEvent) => {
      if (!checkboxRef.current || disabled) {
        return;
      }
      event.preventDefault();
      const newVal = !checkboxRef.current.checked;
      checkboxRef.current.checked = newVal;

      if (typeof onChange === "function") {
        // Create a synthetic event
        const syntheticEvent = ({
          ...event,
          target: {
            ...checkboxRef.current,
            checked: newVal,
            id: assignedId,
          },
        } as unknown) as ChangeEvent<HTMLInputElement>;

        onChange(syntheticEvent, newVal);
      }
    },
    [disabled, assignedId, onChange]
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (!event.key.match(/enter|spacebar|\W{1}/i) || !checkboxRef.current) {
        return;
      }
      event.preventDefault();
      onClick((event as unknown) as React.MouseEvent);
    },
    [onChange]
  );

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (typeof onChange === "function" && !disabled) {
        onChange(event, event.target.checked);
      }
    },
    [disabled, onChange]
  );

  return (
    <CheckboxWrapper style={style} background={background}>
      <input
        ref={checkboxRef}
        onChange={onInputChange}
        onKeyDown={onKeyDown}
        type="checkbox"
        tabIndex={-1}
        disabled={disabled}
        checked={checked}
        aria-checked={indeterminate ? "mixed" : checked}
        aria-disabled={disabled}
        aria-describedby={description ? `${assignedId}_description` : undefined}
        data-testid={dataTestId}
        id={assignedId}
        {...props}
      />
      {indeterminate ? (
        <StyledIndeterminate
          onClick={onClick}
          onKeyDown={onKeyDown}
          tabIndex={0}
          aria-disabled={disabled}
          background={background}
          {...props}
        >
          <Indeterminate />
        </StyledIndeterminate>
      ) : (
        <StyledCheckbox
          onClick={onClick}
          onKeyDown={onKeyDown}
          tabIndex={0}
          aria-disabled={disabled}
          background={background}
          {...props}
        >
          <Checkmark />
        </StyledCheckbox>
      )}
      {label && (
        <TextContainer>
          <LabelRow>
            {label && (
              <Label
                onClick={onClick}
                bold
                htmlFor={assignedId}
                data-tooltip-content={dataTip2 || dataTip1}
                data-tooltip-id={dataTooltipId}
              >
                {label}
              </Label>
            )}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </LabelRow>
          {description && (
            <Description id={`${assignedId}_description`}>
              {description}
            </Description>
          )}
        </TextContainer>
      )}
    </CheckboxWrapper>
  );
};

const StyledCheckbox = styled.span<{ background?: string }>`
  box-sizing: content-box;
  cursor: pointer;
  height: 16px;
  width: 16px;
  border-radius: var(--radius-sm);
  background-color: transparent;
  border: 2px solid ${({ background }) => background || "var(--color-stroke)"};
  position: relative;
  flex-shrink: 0;

  &:focus-visible {
    outline: 0;
    :after {
      content: "";
      position: absolute;
      top: -3px;
      left: -3px;
      right: -3px;
      bottom: -3px;
      border-radius: var(--radius-sm);
      box-shadow: 0 0 0 1px var(--color-focus-indicator);
    }
  }
`;

const StyledIndeterminate = styled(StyledCheckbox)``;

const Checkmark = styled(IconCheck)`
  display: none;
  fill: var(--color-surface);
  width: 16px;
  height: 16px;
`;

const Indeterminate = styled(IconRemove)`
  position: absolute;
  fill: var(--color-surface);
  width: 16px;
  height: 16px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-xxs, 2px);
  margin-left: var(--space-sm);
`;

const LabelRow = styled.div`
  display: flex;
  gap: var(--space-sm, 8px);
`;

const Subtitle = styled(BodyUI)`
  color: var(--color-secondary-text-on-light, #2b313b);
  font-size: 12px;
`;

const Description = styled(BodyUI)`
  color: var(--color-primary-text-on-light, #2b313b);
`;

const Label = styled(BodyUI).attrs({
  as: "label",
})`
  cursor: pointer;
  color: var(--color-primary-text-on-light, #2b313b);
`;

const CheckboxWrapper = styled.div<{ background?: string }>`
  display: flex;
  align-items: flex-start;
  position: relative;
  user-select: none;

  input {
    z-index: -1;
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
  }

  input:checked + ${StyledCheckbox}, input + ${StyledIndeterminate} {
    background: ${({ background }) =>
      background || "var(--color-active-primary)"};
    border: 2px solid
      ${({ background }) => background || "var(--color-active-primary)"};
  }

  input:checked + ${StyledCheckbox} > svg,
  input + ${StyledIndeterminate} > svg {
    display: block;
  }

  input:disabled + ${StyledCheckbox}, input:disabled + ${StyledIndeterminate} {
    border: 2px solid var(--color-disabled-text-on-light);
    cursor: not-allowed;
  }

  input:disabled:checked
    + ${StyledCheckbox},
    input:disabled
    + ${StyledIndeterminate} {
    background: var(--color-disabled-text-on-light);
  }

  input:disabled ~ div {
    p,
    label {
      cursor: default;
      color: var(--color-disabled-text-on-light);
    }
  }

  :hover {
    ${StyledCheckbox}, ${StyledIndeterminate} {
      border-color: ${({ background }) =>
        background || "var(--color-stroke-darken)"};
    }
    input:checked:not(:disabled)
      + ${StyledCheckbox},
      input:not(:disabled)
      + ${StyledIndeterminate} {
      background: ${({ background }) =>
        background || "var(--color-active-darken10)"};
      border: 2px solid
        ${({ background }) => background || "var(--color-active-darken10)"};
    }
  }
`;

export default Checkbox;
