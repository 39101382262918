import { jsx as t } from "react/jsx-runtime";
const i = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ t("path", { d: "M12.667 11.333V8.667H3.333v2.666h9.334Zm0-4V4.667H3.333v2.666h9.334Zm-9.334 5.334c-.366 0-.68-.131-.941-.392A1.284 1.284 0 0 1 2 11.333V4.667c0-.367.13-.681.392-.942.26-.261.575-.392.941-.392h9.334c.366 0 .68.13.941.392.261.261.392.575.392.942v6.666c0 .367-.13.68-.392.942a1.284 1.284 0 0 1-.941.392H3.333Z" })
  }
);
export {
  i as default
};
