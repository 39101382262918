import { ChangeEvent, useCallback, useMemo, useRef } from "react";
import styled from "styled-components";
import { BodyUI } from "@happeouikit/typography";
import { uuidv4 } from "./utils";
import { SwitchProps } from "./Switch.types";

const Switch = ({
  label,
  subtitle,
  description,
  tabIndex,
  id,
  disabled,
  checked,
  defaultChecked,
  required,
  onChange,
  background,
  "data-tip": dataTip1,
  "data-tooltip-content": dataTip2,
  "data-tooltip-id": dataTipId,
  "data-testid": dataTestId,
  ...props
}: SwitchProps) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const onClick = useCallback(
    (event: React.MouseEvent) => {
      if (disabled) {
        return;
      }
      event.preventDefault();

      if (checkboxRef.current) {
        const newVal = !checkboxRef.current.checked;
        // Since this component can be used as controlled or uncontrolled
        // it should have the ability to change aria-label based on its state
        event.currentTarget.setAttribute("aria-checked", newVal.toString());
        checkboxRef.current.checked = newVal;

        // Create a synthetic event
        const syntheticEvent = {
          ...event,
          target: {
            ...checkboxRef.current,
            checked: newVal,
            id: assignedId,
          },
        };

        if (typeof onChange === "function") {
          onChange(
            (syntheticEvent as unknown) as ChangeEvent<HTMLInputElement>,
            newVal
          );
        }
      }
    },
    [onChange]
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key.match(/enter|spacebar|\W{1}/i)) {
        onClick((event as unknown) as React.MouseEvent);
      }
    },
    [onClick]
  );

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (disabled) {
        return;
      }

      if (typeof onChange === "function") {
        onChange(event, event.target.checked);
      }
    },
    [onChange, disabled]
  );

  const assignedId = useMemo(() => id || uuidv4(), [id]);

  return (
    <ToggleSwitchWrapper background={background}>
      <HiddenInput
        aria-hidden="true"
        onChange={onInputChange}
        onKeyDown={onKeyDown}
        ref={checkboxRef}
        type="checkbox"
        tabIndex={-1}
        disabled={disabled}
        checked={checked}
        required={required}
        id={assignedId}
        data-testid={dataTestId}
        aria-describedby={description ? `${assignedId}_description` : undefined}
      />
      <StyledSwitch
        type="button"
        role="switch"
        aria-checked={checked || defaultChecked || false}
        onKeyDown={onKeyDown}
        onClick={onClick}
        disabled={disabled}
        background={background}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {label && (
        <TextContainer>
          <LabelRow>
            {label && (
              <Label
                bold
                htmlFor={assignedId}
                data-tooltip-content={dataTip2 || dataTip1}
                data-tooltip-id={dataTipId}
                onClick={onClick}
              >
                {label}
              </Label>
            )}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </LabelRow>
          {description && (
            <Description id={`${assignedId}_description`}>
              {description}
            </Description>
          )}
        </TextContainer>
      )}
    </ToggleSwitchWrapper>
  );
};

const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  pointer-events: none;
  margin: 0;
`;

const StyledSwitch = styled.button<{ background?: string }>`
  all: unset;
  position: relative;
  cursor: pointer;
  width: 32px;
  height: 16px;
  background-color: ${({ background }) => background || "var(--color-stroke)"};
  border: 2px solid ${({ background }) => background || "var(--color-stroke)"};
  border-radius: var(--radius-pill);
  flex-shrink: 0;

  :active {
    transform: none;
    box-shadow: none;
  }

  :before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 0px;
    top: 0px;
    border-radius: var(--radius-circle);
    background-color: var(--color-surface);
    transition: var(--animation-duration-normal-md);
    box-shadow: var(--box-shadow-shadow100);
  }

  &:focus-visible {
    ::after {
      content: "";
      position: absolute;
      top: -3px;
      left: -3px;
      right: -3px;
      bottom: -3px;
      border-radius: 24px;
      outline: 1px solid var(--color-focus-indicator);
    }
  }

  &:hover {
    background-color: ${({ background }) =>
      background || "var(--color-stroke-darken)"};
    border-color: ${({ background }) =>
      background || "var(--color-stroke-darken)"};
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-xxs, 2px);
  margin-left: var(--space-sm);
`;

const LabelRow = styled.div`
  display: flex;
  gap: var(--space-sm, 8px);
`;

const Subtitle = styled(BodyUI)`
  color: var(--color-secondary-text-on-light, #2b313b);
  font-size: 12px;
`;

const Description = styled(BodyUI)`
  color: var(--color-primary-text-on-light, #2b313b);
`;

const Label = styled(BodyUI).attrs({
  as: "label",
})`
  cursor: pointer;
  color: var(--color-primary-text-on-light, #2b313b);
`;

const ToggleSwitchWrapper = styled.div<{ background?: string }>`
  display: flex;
  align-items: flex-start;
  position: relative;

  input {
    z-index: -1;
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
  }

  input:checked + ${StyledSwitch} {
    border-color: ${({ background }) =>
      background || "var(--color-active-primary)"};
    background: ${({ background }) =>
      background || "var(--color-active-primary)"};
  }

  input:checked + ${StyledSwitch}:before {
    transform: translateX(16px);
  }

  input:disabled ~ div {
    p,
    label {
      cursor: default;
      color: var(--color-disabled-text-on-light);
    }
  }

  input:disabled + ${StyledSwitch} {
    cursor: not-allowed;
    border-color: transparent;
    background-color: var(--color-disabled-text-on-light);
  }
`;

export default Switch;
