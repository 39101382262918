import React from "react";
import styled, { css } from "styled-components";
import {
  Tooltip as ReactTooltip,
  ITooltip as ReactTooltipProps,
} from "react-tooltip";

interface BaseTooltipProps extends Omit<ReactTooltipProps, "content"> {
  /** we need to allow intl.formatMessage(...) as content -- which the types forbid */
  content?: React.ReactNode;
  /** @deprecated use data-tooltip-class-name="small" instead */
  small?: boolean;
}

interface PropsWithId extends BaseTooltipProps {
  id: string;
}

interface PropsWithAnchorSelect extends BaseTooltipProps {
  /**
   * As an alternative to matching id and an anchor element's
   * data-for/data-tooltip-id, you can use this anchorSelect with CSS selectors.
   *
   * See examples: https://react-tooltip.com/docs/examples/anchor-select
   * */
  anchorSelect: string;
}

type TooltipProps = PropsWithId | PropsWithAnchorSelect;

/**
 * In our React app have a single root-level tooltip which should be used as
 * often as possible, for all "basic" tooltips (those that don't require
 * special rendering logic).
 *
 * To use the simple shared tooltip, just give your element these attributes:
 * - data-tooltip-content: the content of the tooltip
 * - data-tooltip-id: "simple-shared-tooltip"  (exactly this id)
 * - (Optional) data-tooltip-class-name="small"
 * - (Optional) data-tooltip-place="bottom" (or other option)
 * - (Optional) data-tooltip-hidden="true"
 *
 * You can replace data-tooltip-content with data-tooltip-html if you want
 * to display simple HTML (e.g. <b>bold</b>).
 *
 * To use this component on its own (for custom-rendered tooltips):
 * 1. Place it next to the element you want to show the tooltip for
 * 2. Give the tooltip a unique id attribute
 * 3. Give the other element two new attributes:
 *   - data-tooltip-content: the content of the tooltip
 *   - data-tooltip-id: the unique id of the tooltip
 *
 * It's okay to have one Tooltip serve multiple elements, but it's not okay
 * to have multiple Tooltips with the same ID on the same page.
 *
 * Example usage:
 * ```tsx
 *   <IconHeartFill
 *     data-tooltip-content="Example text"
 *     data-tooltip-id="example-tooltip"
 *   />
 *   <Tooltip id="example-tooltip" />
 * ```
 *
 * To upgrade to V5 (@happeouikit/tooltip 2.0.0):
 *
 * - data-for attribute now is data-tooltip-id
 * - data-tip attribute now is data-tooltip-content
 * - getContent prop was removed, use content instead (no longer a function)
 * - no need to call rebuild() anymore
 * - show() and hide() can be replaced with setIsOpen()
 * - effect is now always solid by default
 */
export const Tooltip = (props: TooltipProps) => {
  return <StyledTooltip content={props.content as any} {...props} />;
};

const StyledTooltip = styled(ReactTooltip)<{
  small?: boolean;
}>`
  transition-delay: 0.5s !important;
  z-index: var(--z-index-tooltip, 1403);
  // react-tooltip normally sets font-size: 90%, this override fits our styles better
  font-size: 13px !important;

  &.small {
    padding: 2px 8px !important;
    font-size: 12px !important;
  }

  /* deprecated */
  ${({ small }) =>
    small &&
    css`
      padding: 2px 8px !important;
      font-size: 12px !important;
    `};
`;
