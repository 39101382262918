import { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  IconArrowDropDown,
  IconArrowDropUp,
  IconInfoRound,
} from "@happeouikit/icons";
import { TextEta } from "@happeouikit/typography";
import { active, gray01, darken20 } from "@happeouikit/colors";
import { screen } from "@happeouikit/theme";
import { Tooltip } from "@happeouikit/tooltip";
import { LiStriped } from "./List";

const ListHeader = ({ headers, sortDir, sortField, sortFn, padding }) => {
  const handleClick = (headerField) => {
    if (headerField && typeof sortFn === "function") {
      sortFn(headerField);
    }
  };

  return (
    <HeaderRow>
      {headers.map((header) => {
        if (header.skip) return null;

        const color =
          sortField === header.field && header.sortable ? active : gray01;

        return (
          <Fragment key={`${header.name}-${header.field}`}>
            <HeaderCol
              mobileHidden={header.mobileHidden}
              width={header.width}
              onClick={header.sortable ? () => handleClick(header.field) : null}
              padding={padding}
              sortable={header.sortable}
              data-tracker={header.dataTracker}
              tabIndex={header.sortable ? 0 : -1}
            >
              <TextEta bold color={color}>
                {header.name}
              </TextEta>
              {header.tooltip && (
                <TooltipIcon
                  data-tooltip-content={header.tooltip}
                  data-tooltip-id={`list-tooltip-${header.field}`}
                />
              )}
              <SortIcon
                color={color}
                sortDir={sortDir}
                isSorted={sortField === header.field}
                sortable={header.sortable}
              />
            </HeaderCol>
            <Tooltip id={`list-tooltip-${header.field}`} />
          </Fragment>
        );
      })}
    </HeaderRow>
  );
};

const TooltipIcon = styled(IconInfoRound)`
  svg {
    width: 20px;
    height: 20px;
  }
  margin-left: 4px;
`;

const HeaderCol = styled.button`
  all: unset;
  display: flex;
  position: relative;
  align-items: center;
  width: ${({ width }) => width || "auto"};
  padding-right: ${({ padding }) => padding || "5px"};
  cursor: ${({ sortable }) => (sortable ? "pointer" : "default")};

  ${({ mobileHidden }) =>
    mobileHidden &&
    `
    @media ${screen.onlyXs} {
      display: none;
    }
  `}

  svg {
    overflow: visible;
  }

  :active {
    box-shadow: none;
    transform: none;
    background-color: transparent;

    p {
      color: ${darken20(active)};
    }
    svg {
      fill: ${darken20(active)};
    }
  }

  :focus-visible {
    outline: 1px solid ${active};
    border-radius: 4px;
  }

  :hover {
    background-color: transparent;
  }

  ${({ sortable }) =>
    sortable &&
    `
  :hover {
    p {
      color: ${active};
    }
    svg {
      fill: ${active};
    }
  }
  `}
`;

const SortIcon = ({ sortDir, sortable, isSorted, color }) => {
  const iconProps = {
    width: "24px",
    height: "21px",
    fill: color,
  };

  if (!sortable) {
    return null;
  }

  if (!sortDir.includes("desc") && isSorted) {
    return (
      <IconArrowDropUp
        width={iconProps.width}
        height={iconProps.height}
        fill={iconProps.fill}
      />
    );
  }

  return (
    <IconArrowDropDown
      width={iconProps.width}
      height={iconProps.height}
      fill={iconProps.fill}
    />
  );
};

ListHeader.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  sortDir: PropTypes.string,
  sortFn: PropTypes.func,
};

ListHeader.defaultProps = {
  sortDir: "desc",
  sortFn: undefined,
};

const HeaderRow = styled(LiStriped)`
  justify-content: flex-start;
  && {
    background: transparent;
  }
  box-shadow: none;

  @media ${screen.onlyXs} {
    justify-content: space-between;
  }
`;

export default ListHeader;
